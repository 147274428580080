<template>
  <div
    ref="appTableContainerRef"
    :class="containerStyle"
  >
    <app-section-title
      :title="title"
      class="mb-3.5"
    />

    <form-control-text
      name="employees_search"
      :icon-props="{
        library: 'coolicon',
        name: 'search',
        classes: ['form-icon-theme'],
        size: 'text-2xl',
      }"
      :placeholder="t('ttmt.form.select.type_to_search')"
      class="max-w-md mb-4"
      @changed="handleSearch"
    />

    <app-table
      :headers="employeesHeaders"
      :data="employeesTableData"
      :loading="employeesLoading"
    />
  </div>

  <app-pagination
    :links="links"
    :change-page-callback="handleChangePage"
    :element-to-scroll-to="appTableContainerRef"
    class="mt-5"
  />
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { isNil } from 'lodash'

import {
  fetchUsersInteractionButler,
  fetchUsersInstitutionsButler,
} from '@shared/http/api'
import FormControlText from '@shared/components/form/controls/FormControlText.vue'
import AppUserWithAvatar from '@extranet/components/ui/user/AppUserWithAvatar.vue'
import AppPagination from '@extranet/components/ui/AppPagination.vue'
import AppSectionTitle from '@extranet/components/ui/AppSectionTitle.vue'
import AppTable from '@extranet/components/ui/table/AppTable.vue'
import EmployeeTableActions from '@extranet/components/resources/employee/EmployeeTableActions.vue'

const props = defineProps({
  // CSS for container
  containerStyle: {
    type: String,
    default: null,
  },
  // Component main title
  mainTitle: {
    type: String,
    default: null,
  },
  // Mode for API request
  mode: {
    type: String,
    required: true,
    validator(value) {
      // The value must match one of these strings
      return [
        'interactions',
        'institutions',
      ].includes(value)
    },
  },
  // Define a POI id for filtering
  poi: {
    type: Number,
    default: null,
  },
})

const { t } = useI18n()

// ---------- API MODE ----------

const apiRequest = computed(() => {
  switch (props.mode) {
    case 'interactions':
      return fetchUsersInteractionButler
    case 'institutions':
    default:
      return fetchUsersInstitutionsButler
  }
})

// ---------- TITLE ----------

const title = computed(() => (
  isNil(props.mainTitle)
    ? t('ttmt.users.butlers')
    : props.mainTitle
))

// ---------- EMPLOYEES ----------

const employees = ref([])
const employeesLoading = ref(true)
const nextPageFetching = ref(false)

function fetchEmployees(nextPage = false) {
  if (nextPage === false) {
    employeesLoading.value = true
  }

  nextPageFetching.value = nextPage

  const params = {
    'page': page.value,
    'search': searchKeywords.value,
    'poi': props.poi,
  }

  apiRequest.value(params)
    .then((response) => {
      employees.value = response.data.data ?? []

      // Pagination links
      links.value = response.data.meta?.links ?? []
    })
    .finally(() => {
      employeesLoading.value = false
    })
}

const employeesHeaders = [
  { label: t('validation.attributes.employee') },
  { label: t('validation.attributes.actions') },
]

const employeesTableData = computed(() => (
  employees?.value?.map((employee) => ({
    title: {
      component: AppUserWithAvatar,
      props: {
        resource: employee,
      },
    },
    actions: {
      component: EmployeeTableActions,
      props: {
        resource: employee,
      },
    },
  }))
))

// ---------- SEARCH ----------

const typingTimer = ref(0)
const searchKeywords = ref(null)

function handleSearch(value) {
  // Wait a little before emit the 'searched event'
  // it allows user to finish typing, and avoid triggering
  // an api call at each keystroke
  if (typingTimer.value) {
    clearTimeout(typingTimer.value)
  }

  typingTimer.value = setTimeout(() => {
    resetPage()
    searchKeywords.value = value
    fetchEmployees()
  }, 400)
}

// ---------- PAGINATION ----------

const appTableContainerRef = ref(null)
const links = ref([])
const page = ref(1)

function resetPage() {
  page.value = 1
}

function handleChangePage(value) {
  page.value = value
  fetchEmployees()
}

// ---------- LIFECYCLES ----------

onMounted(() => {
  fetchEmployees()
})
</script>
