<template>
  <p class="text-base text-gray-500 my-4">
    {{ displayTitle }}
  </p>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps({
  // Text to display
  title: {
    type: String,
    default: null,
  },
})

const { t } = useI18n()

const displayTitle = computed(() => (
  props.title ? props.title : t('ttmt.common.messages.no_records')
))
</script>
