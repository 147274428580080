<template>
  <div class="flex items-center">
    <app-button
      feature="show"
      emphasis="low"
      hide-text
      :hide-icon="false"
      class="mr-3"
      @click="handleShow(resource.id)"
    />

    <app-button
      feature="list"
      :label="t('ttmt.common.actions.interactions')"
      emphasis="low"
      hide-text
      :hide-icon="false"
      @click="handleInteractions(resource.id)"
    />
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import AppButton from '@shared/components/ui/AppButton.vue'
import useFilters from '@shared/hooks/filters'

defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
})

const router = useRouter()
const { t } = useI18n()

// ---------- INTERACTIONS ----------

const {
  handleFilterChange,
} = useFilters('interactions')

function handleInteractions(id) {
  handleFilterChange('user_id', id)
  router.push({ name: 'index-interactions' })
}

// ---------- SHOW ----------

function handleShow(id) {
  router.push({
    name: 'show-users',
    params: { id },
  })
}
</script>
