<template>
  <!-- Loading -->
  <tutorial-loading
    v-if="loading"
  />

  <app-card-item
    v-else
    class="p-3 mb-5 flex items-center flex-col"
    background-classes="bg-gray-200 text-center"
  >
    <app-icon
      library="fontawesome"
      name="user-tie"
      size="2x"
      class="mb-2"
    />

    <p class="font-bold text-xl mb-4">
      {{ t('ttmt.tutorials.extranet.dashboard.employees.title') }}
    </p>

    <p class="font-bold mb-4">
      {{ t('ttmt.tutorials.extranet.dashboard.employees.subtitle.part_1') }}

      <router-link
        :to="{ name: 'index-institutions-employees' }"
        class="app-link"
      >
        {{ t('ttmt.tutorials.extranet.dashboard.employees.subtitle.part_2') }}
      </router-link>
    </p>

    <p class="mb-4">
      {{ t('ttmt.tutorials.extranet.dashboard.employees.manage') }}
    </p>

    <!-- eslint-disable vue/no-v-html -->
    <p v-html="t('ttmt.tutorials.extranet.dashboard.employees.link')" />
  </app-card-item>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import AppIcon from '@shared/components/ui/AppIcon.vue'
import TutorialLoading from '@extranet/components/dashboard/shared/tutorial/TutorialLoading.vue'

const { t } = useI18n()

defineProps({
  // Is it loading?
  loading: {
    type: Boolean,
    default: false,
  },
})
</script>
