<template>
  <div class="flex flex-col mb-16">
    <app-section-title
      :title="t('ttmt.extranet.dashboard.sections.stats')"
      class="mb-9"
    />

    <div class="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-4">
      <app-stat-card-wrapper
        :loading="institutionsCountLoading"
        :icon-props="{ library: 'coolicon', name: 'building', size: 'text-6xl' }"
        background-image="/images/extranet/stats/institutions.jpg"
        :label="t('ttmt.extranet.dashboard.stats.institutions', institutionsCount)"
        :value="institutionsCount"
        route-name="institutions"
      />

      <app-stat-card-wrapper
        :loading="claimsCountLoading"
        :icon-props="{ library: 'coolicon', name: 'flag_outline', size: 'text-6xl' }"
        background-image="/images/extranet/stats/claims_hotelier.jpg"
        :label="t('ttmt.extranet.dashboard.stats.processing_claims', claimsCount)"
        :value="claimsCount"
        route-name="institutions"
      />

      <app-stat-card-wrapper
        :loading="butlersCountLoading"
        :icon-props="{ library: 'fontawesome', name: 'user-tie', size: 'xs' }"
        background-image="/images/extranet/stats/butlers.jpg"
        :label="t('ttmt.extranet.dashboard.stats.butlers', butlersCount)"
        :value="butlersCount"
        route-name="index-institutions-employees"
      />
    </div>
  </div>

  <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
    <!-- Claims -->
    <last-claims
      :resources="claims"
      :loading="claimsLoading"
    />

    <!-- Employees -->
    <my-employees
      :loading="butlersCountLoading"
      :employees-count="butlersCount"
    />
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import {
  fetchStatInstitutions as apiFetchStatInstitutions,
  fetchStatClaims as apiFetchStatClaims,
  fetchStatEmployees as apiFetchStatEmployees,
  fetchLastClaims,
} from '@shared/http/api'
import AppStatCardWrapper from '@extranet/components/ui/stat_card/AppStatCardWrapper.vue'
import AppSectionTitle from '@extranet/components/ui/AppSectionTitle.vue'
import LastClaims from '@extranet/components/dashboard/shared/LastClaims.vue'
import MyEmployees from '@extranet/components/dashboard/shared/MyEmployees.vue'

const { t } = useI18n()

// ---------- INSTITUTIONS ----------

const institutionsCount = ref(0)
const institutionsCountLoading = ref(true)

function fetchInstitutionsCount() {
  apiFetchStatInstitutions()
    .then((response) => {
      institutionsCount.value = response.data.data?.value
    })
    .finally(() => {
      institutionsCountLoading.value = false
    })
}

// ---------- CLAIMS ----------

const claims = ref([])
const claimsCount = ref(0)
const claimsLoading = ref(true)
const claimsCountLoading = ref(true)

function fetchClaims() {
  fetchLastClaims()
    .then((response) => {
      claims.value = response.data.data ?? []
    })
    .finally(() => {
      claimsLoading.value = false
    })
}

function fetchClaimsCount() {
  apiFetchStatClaims()
    .then((response) => {
      claimsCount.value = response.data.data?.value
    })
    .finally(() => {
      claimsCountLoading.value = false
    })
}

// ---------- BUTLERS ----------

const butlersCount = ref(0)
const butlersCountLoading = ref(true)

function fetchButlersCount() {
  apiFetchStatEmployees({ role: 'butler' })
    .then((response) => {
      butlersCount.value = response.data.data?.value
    })
    .finally(() => {
      butlersCountLoading.value = false
    })
}

// ---------- LIFECYCLES ----------

onMounted(() => {
  fetchInstitutionsCount()
  fetchClaims()
  fetchClaimsCount()
  fetchButlersCount()
})
</script>
