import { computed } from 'vue'
import { useStore } from 'vuex'

export default function useAccess(user = null) {
  const store = useStore()

  const currentUser = user ?? store.state.auth.user
  const access = currentUser.authorizations.access

  // ---------- DASHBOARDS ----------

  /**
   * Check if user can access to hotelier
   * dashboard (or false if not found)
   *
   * @returns {boolean}
   */
  const dashboardHotelierAccess = computed(() => (
    access?.dashboard_hotelier ?? false
  ))

  /**
   * Check if user can access to manager
   * dashboard (or false if not found)
   *
   * @returns {boolean}
   */
  const dashboardManagerAccess = computed(() => (
    access?.dashboard_manager ?? false
  ))

  /**
   * Check if user can access to butler
   * dashboard (or false if not found)
   *
   * @returns {boolean}
   */
  const dashboardButlerAccess = computed(() => (
    access?.dashboard_butler ?? false
  ))

  /**
   * Check if user can access to tourist office
   * dashboard (or false if not found)
   *
   * @returns {boolean}
   */
  const dashboardTouristOfficeAccess = computed(() => (
    access?.dashboard_tourist_office ?? false
  ))

  /**
   * Check if user can access to tourist office
   * employee dashboard (or false if not found)
   *
   * @returns {boolean}
   */
  const dashboardTouristOfficeEmployeeAccess = computed(() => (
    access?.dashboard_tourist_office_employee ?? false
  ))

  // ---------- FUNCTIONALITIES ----------

  /**
   * Check if user can access to institutions
   * (plural!)
   *
   * @returns {boolean}
   */
  const institutionsAccess = computed(() => (
    dashboardManagerAccess.value
      || dashboardHotelierAccess.value
      || dashboardTouristOfficeAccess.value
  ))

  /**
   * Check if user can access to institution
   * (singular!)
   *
   * @returns {boolean}
   */
  const institutionAccess = computed(() => (
    dashboardButlerAccess.value
      || dashboardTouristOfficeEmployeeAccess.value
  ))

  /**
   * Check if user can access to employees
   *
   * @returns {boolean}
   */
  const employeesAccess = computed(() => (
    dashboardHotelierAccess.value
      || dashboardTouristOfficeAccess.value
  ))

  /**
   * Check if user can access to excursions
   *
   * @returns {boolean}
   */
  const excursionsAccess = computed(() => (
    dashboardButlerAccess.value
      || dashboardTouristOfficeEmployeeAccess.value
      || dashboardHotelierAccess.value
      || dashboardTouristOfficeAccess.value
  ))

  /**
   * Check if user can access to travellers
   *
   * @returns {boolean}
   */
  const travellersAccess = computed(() => (
    dashboardButlerAccess.value
      || dashboardTouristOfficeEmployeeAccess.value
  ))

  /**
   * Check if user can access to traveller emails
   *
   * @returns {boolean}
   */
  const travellerEmailsAccess = computed(() => (
    dashboardHotelierAccess.value
      || dashboardTouristOfficeAccess.value
  ))

  /**
   * Check if user can access to invitations
   *
   * @returns {boolean}
   */
  const invitationsAccess = computed(() => (
    dashboardButlerAccess.value
      || dashboardTouristOfficeEmployeeAccess.value
  ))

  /**
   * Check if user can access to contacts
   *
   * @returns {boolean}
   */
  const contactsAccess = computed(() => (
    dashboardButlerAccess.value
      || dashboardTouristOfficeEmployeeAccess.value
  ))

  // ---------- PAYMENTS ----------

  /**
   * Check if user can access to institutions
   * payments received
   *
   * @returns {boolean}
   */
  const paymentsReceivedByInstitutionAccess = computed(() => (
    dashboardHotelierAccess.value
      || dashboardTouristOfficeAccess.value
  ))

  /**
   * Check if user can access to payments
   * received
   *
   * @returns {boolean}
   */
  const paymentsReceivedAccess = computed(() => (
    dashboardButlerAccess.value
      || dashboardTouristOfficeEmployeeAccess.value
  ))

  /**
   * Check if user can access to payments
   * issued
   *
   * @returns {boolean}
   */
  const paymentsIssuedAccess = computed(() => (
    dashboardManagerAccess.value
  ))

  return {
    dashboardHotelierAccess,
    dashboardManagerAccess,
    dashboardButlerAccess,
    dashboardTouristOfficeAccess,
    dashboardTouristOfficeEmployeeAccess,
    institutionsAccess,
    institutionAccess,
    employeesAccess,
    excursionsAccess,
    travellersAccess,
    travellerEmailsAccess,
    invitationsAccess,
    contactsAccess,
    paymentsReceivedByInstitutionAccess,
    paymentsReceivedAccess,
    paymentsIssuedAccess,
  }
}
