<template>
  <employee-table
    v-if="showEmployees"
    :main-title="t('ttmt.extranet.dashboard.manager.sections.butlers_institutions')"
    mode="institutions"
  />

  <!-- Tutorial -->
  <tutorial-employees
    v-else
    :loading="loading"
  />
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import EmployeeTable from '@extranet/components/resources/employee/EmployeeTable.vue'
import TutorialEmployees from '@extranet/components/dashboard/shared/tutorial/TutorialEmployees.vue'

const props = defineProps({
  // Is it loading?
  loading: {
    type: Boolean,
    default: false,
  },
  // Employees count
  employeesCount: {
    type: Number,
    default: 0,
  },
})

const { t } = useI18n()

// ---------- DISPLAY ----------

const showEmployees = computed(() => (
  props.employeesCount > 0
    && !props.loading
))
</script>
