<template>
  <div class="flex flex-col mb-16">
    <app-section-title
      :title="t('ttmt.extranet.dashboard.sections.stats')"
      class="mb-9"
    />

    <div class="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-4">
      <app-stat-card-wrapper
        :loading="foldersCountLoading"
        :icon-props="{ library: 'coolicon', name: 'folder', size: 'text-6xl' }"
        background-image="/images/extranet/stats/folders.jpg"
        :label="t('ttmt.extranet.dashboard.stats.folders', foldersCount)"
        :value="foldersCount"
        route-name="travellers"
      />

      <app-stat-card-wrapper
        :loading="excursionsCountLoading"
        :icon-props="{ library: 'coolicon', name: 'map', size: 'text-6xl' }"
        background-image="/images/extranet/stats/excursions.jpg"
        :label="t('ttmt.extranet.dashboard.stats.excursions', excursionsCount)"
        :value="excursionsCount"
        route-name="index-excursions"
      />
    </div>
  </div>

  <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
    <last-traveller-folders
      :resources="folders"
      :loading="foldersLoading"
    />

    <last-payments
      :resources="payments"
      :loading="paymentsLoading"
    />
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import {
  fetchStatFolders,
  fetchStatExcursions,
  fetchLastButlerTravellerFolder,
  fetchLastPayments,
} from '@shared/http/api'
import AppSectionTitle from '@extranet/components/ui/AppSectionTitle.vue'
import AppStatCardWrapper from '@extranet/components/ui/stat_card/AppStatCardWrapper.vue'
import LastTravellerFolders from '@extranet/components/dashboard/shared/LastTravellerFolders.vue'
import LastPayments from '@extranet/components/dashboard/shared/LastPayments.vue'

const { t } = useI18n()

// ---------- FOLDERS ----------

const folders = ref([])
const foldersCount = ref(0)
const foldersLoading = ref(true)
const foldersCountLoading = ref(true)

function fetchFolders() {
  fetchLastButlerTravellerFolder()
    .then((response) => {
      folders.value = response.data.data ?? []
    })
    .finally(() => {
      foldersLoading.value = false
    })
}

function fetchFoldersCount() {
  fetchStatFolders()
    .then((response) => {
      foldersCount.value = response.data.data?.value
    })
    .finally(() => {
      foldersCountLoading.value = false
    })
}

// ---------- EXCURSIONS ----------

const excursionsCount = ref(0)
const excursionsCountLoading = ref(true)

function fetchExcursionsCount() {
  fetchStatExcursions()
    .then((response) => {
      excursionsCount.value = response.data.data?.value
    })
    .finally(() => {
      excursionsCountLoading.value = false
    })
}

// ---------- PAYMENTS ----------

const payments = ref([])
const paymentsLoading = ref(true)

function fetchPayments() {
  fetchLastPayments()
    .then((response) => {
      payments.value = response.data.data?.map((payment) => ({
        resource: payment,
      })) ?? []
    })
    .finally(() => {
      paymentsLoading.value = false
    })
}

// ---------- LIFECYCLES ----------

onMounted(() => {
  fetchFoldersCount()
  fetchExcursionsCount()
  fetchFolders()
  fetchPayments()
})
</script>
