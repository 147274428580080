<template>
  <div
    v-if="showClaims"
    :class="margins"
  >
    <!-- Title -->
    <app-section-title
      :title="t('ttmt.extranet.dashboard.sections.last_claims')"
      class="mb-3.5"
    />

    <!-- Table -->
    <app-table
      v-if="loading || tableData.length > 0"
      :headers="tableHeaders"
      :data="tableData"
      :loading="loading"
      class="mb-4"
    />

    <!-- No records -->
    <app-card-item
      v-else
      class="p-10 text-center mb-4"
    >
      {{ t('ttmt.extranet.dashboard.last_claims.none') }}
    </app-card-item>

    <!-- Index link -->
    <router-link
      v-if="!loading"
      :to="{ name: 'index-claims' }"
      class="app-link text-xl block mx-auto w-fit"
    >
      <span v-if="tableData.length > 0">
        {{ t('ttmt.extranet.dashboard.last_claims.see_all') }}
      </span>

      <span v-else>
        {{ t('ttmt.extranet.dashboard.last_claims.see_index') }}
      </span>
    </router-link>
  </div>

  <!-- Tutorial -->
  <tutorial-claim
    v-else
    :loading="loading"
    :class="margins"
  />
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import useClaim from '@extranet/hooks/claim'
import AppSectionTitle from '@extranet/components/ui/AppSectionTitle.vue'
import AppTable from '@extranet/components/ui/table/AppTable.vue'
import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import AppStatusBadge from '@shared/components/ui/AppStatusBadge.vue'
import PointOfInterestWithMainPicture from '@extranet/components/resources/point_of_interest/PointOfInterestWithMainPicture.vue'
import ClaimTableActions from '@extranet/components/resources/claim/ClaimTableActions.vue'
import TutorialClaim from '@extranet/components/dashboard/shared/tutorial/TutorialClaim.vue'

const props = defineProps({
  // Folders JSON API resources
  resources: {
    type: Array,
    default: () => ([]),
  },
  // Is it loading?
  loading: {
    type: Boolean,
    default: false,
  },
  // Margins CSS
  margins: {
    type: String,
    default: '',
  },
})

const { t } = useI18n()
const { statusBadgeDesign } = useClaim()

// ---------- TABLE ----------

const tableHeaders = [
  { label: t('validation.attributes.point_of_interest') },
  { label: t('validation.attributes.state') },
  { label: t('validation.attributes.actions') },
]

const tableData = computed(() => (
  props.resources.map((claim) => ({
    poi: {
      component: PointOfInterestWithMainPicture,
      props: {
        resource: claim.relationships?.point_of_interest,
      },
    },
    state: {
      component: AppStatusBadge,
      props: {
        text: t(`ttmt.states.claim_state.${claim.attributes?.state}`),
        design: statusBadgeDesign(claim.attributes?.state),
      },
    },
    actions: {
      component: ClaimTableActions,
      props: {
        resource: claim,
      },
    },
  }))
))

// ---------- CLAIMS ----------

const showClaims = computed(() => (
  props.resources.length > 0
    && !props.loading.value
))
</script>
