<template>
  <div class="mt-8">
    <!-- Title -->
    <app-section-title
      :title="t('ttmt.extranet.dashboard.sections.last_reservations')"
      class="mb-3.5"
    />

    <!-- Table -->
    <app-table
      v-if="loading || tableData.length > 0"
      :headers="tableHeaders"
      :data="tableData"
      :loading="loading"
      class="mb-4"
    />

    <!-- No records -->
    <app-card-item
      v-else
      class="p-10 text-center mb-4"
    >
      {{ t('ttmt.extranet.dashboard.last_reservations.none') }}
    </app-card-item>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import useDatetime from '@shared/hooks/datetime'
import useReservation from '@extranet/hooks/reservation'
import AppSectionTitle from '@extranet/components/ui/AppSectionTitle.vue'
import AppTable from '@extranet/components/ui/table/AppTable.vue'
import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import AppStatusBadge from '@shared/components/ui/AppStatusBadge.vue'
import PointOfInterestWithMainPicture from '@extranet/components/resources/point_of_interest/PointOfInterestWithMainPicture.vue'

const props = defineProps({
  // Folders JSON API resources
  resources: {
    type: Array,
    default: () => ([]),
  },
  // Is it loading?
  loading: {
    type: Boolean,
    default: false,
  },
})

const { t } = useI18n()
const {
  formatDateShort,
  formatTimeSimple,
} = useDatetime()

// ---------- TABLE ----------

const { statusBadgeDesign } = useReservation()

const tableHeaders = [
  { label: t('validation.attributes.point_of_interest') },
  { label: t('validation.attributes.reservation_date') },
  { label: t('validation.attributes.reservation_time') },
  { label: t('validation.attributes.state') },
]

const tableData = computed(() => (
  props.resources.map((reservation) => ({
    institution: {
      component: PointOfInterestWithMainPicture,
      props: {
        resource: reservation.relationships?.point_of_interest,
      },
    },
    reservation_date: formatDateShort(reservation?.attributes.reservation_date),
    reservation_time: formatTimeSimple(reservation?.attributes.reservation_time),
    state: {
      label: t('validation.attributes.state'),
      component: AppStatusBadge,
      props: {
        design: statusBadgeDesign(reservation?.attributes.state),
        text: t(`ttmt.reservations.states.${reservation?.attributes.state}`),
      },
    },
  }))
))
</script>
