<template>
  <div
    :class="`flex items-center ${withModal ? 'cursor-pointer' : ''}`"
    :title="displayName"
    @click="handleClick"
  >
    <div class="mr-2">
      <app-rounded-picture
        :picture-url="avatarUrl"
        picture-size="8"
        icon-name="user"
        icon-size="xs"
      />
    </div>

    <div class="flex flex-col truncate">
      <span class="truncate">
        {{ displayName }}
      </span>

      <span
        v-if="showEmail"
        class="font-semibold text-xs"
      >
        {{ email }}
      </span>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

import AppRoundedPicture from '@shared/components/ui/AppRoundedPicture.vue'
import useResource from '@shared/hooks/resources/resource'
import useUser from '@shared/hooks/resources/user'

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Open profile in a modal or not
  withModal: {
    type: Boolean,
    default: false,
  },
})

const store = useStore()
const { getImageUrlFromResource } = useResource()
const {
  displayName,
  email,
  showEmail,
} = useUser(props)

const avatarUrl = computed(() => (
  getImageUrlFromResource(props.resource, 'xs')
))

function handleClick() {
  if (props.withModal) {
    store.commit('modals/SET_PROPERTIES', {
      name: 'user_show',
      display: true,
      id: props.resource.id,
    })
  }
}
</script>
